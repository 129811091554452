import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "light--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_forward_calls_dropdown = _resolveComponent("forward-calls-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection,
    class: "contacts-table"
  }, {
    "body-cell-caller-slot": _withCtx((props) => [
      _createVNode(_component_router_link, { to: { name: 'base.history.forwardCalls.details' } }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: props.row.caller.name || props.row.caller.phone,
            "avatar-color": props.row.caller.avatarColor,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-color"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-number-slot": _withCtx((props) => [
      _createTextVNode(_toDisplayString(props.row.number.phone) + " ", 1),
      _createElementVNode("span", _hoisted_1, " (" + _toDisplayString(props.row.number.team) + ") ", 1)
    ]),
    "body-cell-arrows-slot": _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: "keyboard_double_arrow_right",
        size: "large",
        class: "success--text"
      })
    ]),
    "body-cell-forwarded-slot": _withCtx((props) => [
      _createVNode(_component_tm_country, {
        country: {
          id: props.row.forwardedTo.country.id,
          name: props.row.forwardedTo.phone,
        },
        size: "xSmall"
      }, null, 8, ["country"])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_forward_calls_dropdown)
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}